<ng-container *transloco="let t">
  <!-- Add focus on body so that flatpickr isn't shown on open -->
  <div class="modal-body" ngbAutoFocus>
    <div class="text-secondary smaller">CLOCK OVERRIDE</div>
    <input #calendar type="text" class="form-control" [(ngModel)]="now" (ngModelChange)="updateNow()"/>
  </div>

  @if (isAbfas()) {

    <div class="ms-2">
      <div class="text-secondary smaller">FIRST YEAR OVERRIDE (UTC)</div>
      <div class="dropdown">
        <button id="year-choice" class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
        aria-expanded="false">
          {{selectedYear() ?? 2021}}
        </button>
        <ul class="dropdown-menu">
          @for(year of yearOptions(); track year) {
            <li>
              <div
                class="dropdown-item clickable"
                (click)="year === 2021 ? this.selectedYear.set(undefined) : this.selectedYear.set(year)"
              >
                {{year === 2021 ? year.toString() + '(Default)' : year}}
              </div>
            </li>
          }
        </ul>
      </div>
    </div>

    <div class="ms-2 mt-2">
      <div class="text-secondary smaller">SELECTION METHOD OVERRIDE</div>
      <div class="dropdown">
        <button id="year-choice" class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
        aria-expanded="false">
          {{selectedSelectionMethod() ?? 'Random (Default)'}}
        </button>
        <ul class="dropdown-menu">
          <li>
            <div
              class="dropdown-item clickable"
              (click)="selectedSelectionMethod.set('unanswered')"
            >
              Unanswered
            </div>
          </li>
          <li>
            <div
              class="dropdown-item clickable"
              (click)="selectedSelectionMethod.set('spaced')"
            >
              Spaced Repetition
            </div>
          </li>
          <li>
            <div
              class="dropdown-item clickable"
              (click)="selectedSelectionMethod.set('oldest')"
            >
              Oldest (Fallback)
            </div>
          </li>
          <li>
            <div
              class="dropdown-item clickable"
              (click)="selectedSelectionMethod.set(undefined)"
            >
              Random (Default)
            </div>
          </li>
        </ul>
      </div>
    </div>
  }

  <div class="mb-3 d-flex">
    <button type="button" class="ms-auto btn btn-sm btn-outline-secondary" (click)="cancel()">Cancel</button>
    <button type="button" class="ms-2 me-3 btn btn-sm btn-outline-primary" (click)="apply()"
            [disabled]="false">Apply</button>
  </div>
</ng-container>
