import { intersection } from 'lodash-es';
import { Course, User } from '../types/types';

export function isHistoryEnabled(user: User, course: Course): boolean {
  if (!course.history) {
    return false;
  }

  const settings = user.courses.find(c => c.id === course.id);
  if (settings) {
    return settings.history === true;
  }

  if (isProgramManager(user)) {
    return true;
  }

  throw new Error(`Course ${course} is not assigned to the user`);
}

export function isE2EUser(user: User): boolean {
  return user.email.startsWith('e2e');
}

export function isProgramManager(user: User): boolean {
  return intersection(user!.features, ['admin', 'buildswell']).length > 0;
}
