import { Component, DestroyRef, inject } from '@angular/core';
import { filter, from, mergeMap, tap } from 'rxjs';
import { RouterModule } from '@angular/router';
import { Auth, authState, signOut, User as FbUser } from '@angular/fire/auth';
import { TrpcService } from '../services/trpc.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { User } from '../types/types';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { StateService } from '../services/state.service';
import { LoadingOverlayService } from '../loading-overlay/loading-overlay.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SetPasswordModalComponent } from '../shared/set-password-modal/set-password-modal.component';
import { ToastService } from '../shared/toast/toast.service';
import { isE2EUser, isProgramManager } from '../shared/user-utils';
import { TranslocoDirective, TranslocoService } from '@ngneat/transloco';
import { CustomParamsModalComponent } from './custom-params-modal/custom-params-modal.component';

@Component({
  selector: 'buildswell-user',
  standalone: true,
  imports: [CommonModule, RouterModule, FormsModule, FontAwesomeModule, TranslocoDirective],
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {
  protected readonly isProgramManager = isProgramManager;

  faUser = faUser;

  fUser: FbUser | undefined;
  user: User | undefined;

  _preview = false;
  private destroyRef = inject(DestroyRef);

  constructor(private auth: Auth,
              private overlay: LoadingOverlayService,
              private state: StateService,
              private trpc: TrpcService,
              private toast: ToastService,
              private transloco: TranslocoService,
              private modalService: NgbModal) {

    authState(this.auth)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(user => this.fUser = user ?? undefined)
      )
      .subscribe()

    this.state.select(state => ({
      user: state.user,
      preview: state.preview,
    }))
      .pipe(
        filter(state => !!state.user),
        tap(state => {
          this.user = state.user!;
          this._preview = state.preview === true
        })
      )
      .subscribe();
  }

  get preview(): boolean {
    return this._preview;
  }

  set preview(preview: boolean) {
    // When preview is true, draft is true by default
    // When preview is false, draft must be false
    this.state.setState({ preview, draft: preview });
  }

  get draft(): boolean {
    return this._preview;
  }

  set draft(draft: boolean) {
    this.state.setState({ draft });
  }

  updatePassword(): void {
    const modal = this.modalService.open(SetPasswordModalComponent);
    modal.closed
      .pipe(
        filter(password => !!password),
        tap(() => this.overlay.show()),
        mergeMap(password => from(this.trpc.client.user.updatePassword.mutate(password))),
        tap(() => this.overlay.hide()),
        tap(() => this.toast.info(this.transloco.translate('user.toast.title'), this.transloco.translate('user.toast.message')))
      )
      .subscribe();
  }

  selectOrganization(organization: string): void {
    if (!this.user || this.user!.organization === organization) {
      return;
    }

    this.overlay.show();
    from(this.trpc.client.user.switch.mutate(organization))
      .pipe(
        tap(() => window.location.reload())
      )
      .subscribe();
  }

  logout() {
    this.overlay.show();
    from(this.trpc.client.sso.signOut.mutate())
      .pipe(
        mergeMap(() => signOut(this.auth))
      )
      .subscribe();
  }

  shouldShowParams(): boolean {
    return isProgramManager(this.user!) || isE2EUser(this.user!);
  }

  setParams(): void {
    const modal = this.modalService.open(CustomParamsModalComponent, {
      beforeDismiss: () => modal.componentInstance.allowDismiss
    });
  }
}
